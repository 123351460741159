@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
body{
  margin: 0 !important
}
.z-index-1 {
  z-index: $zindex-dropdown;
}

.z-index-2 {
  z-index: $zindex-sticky;
}

.z-index-3 {
  z-index: $zindex-fixed;
}

.z-index-4 {
  z-index: $zindex-modal-backdrop;
}

.z-index-5 {
  z-index: $zindex-offcanvas;
}

.z-index-6 {
  z-index: $zindex-modal;
}

.z-index-7 {
  z-index: $zindex-popover;
}

.z-index-8 {
  z-index: $zindex-tooltip;
}

body {
  font-size: 14px;
}

a {
  color: #3c8dbc;
}

.place-top {
  z-index: 9999999;
}

//.brand-link {
//  background-color: #008d4c;
//}

.user-panel {
  .info {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    padding: 1px 5px 5px 10px;

    .info-status {
      font-size: 9px;
    }
  }
}

.card.card-primary.card-outline {
  .card-header {
    border-bottom: 1px solid #c0dfff;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.nav-link.profile {
  cursor: pointer;

  img {
    height: 24px;
    width: 24px;
  }
}

.show-chat {
  font-size: 80%;
}

.nav-link.active-link {
  background-color: #007bff !important;
  color: white !important;

  i {
    margin-left: 1.3rem;
  }
}

.btn {
  &.gutter {
    margin: .35rem .45rem
  }
}

.task-list {
  & > .task {
    border: 1px solid #ebebeb;
  }

  & > .task:nth-child(2n+1) {
    border-radius: 5px;
    background: #d0ead5;
  }

  & > .task:nth-child(2n) {
    border-radius: 5px;
    background: #ffffff;
  }
}

.tasklist-filter {
  margin: 1rem -.45rem;
}

.tag-filter {
  min-width: 230px;
}

$left-menu-bgc: #343a40;
nav.left-menu {
  .nav-icon {
    font-size: 14px !important;
  }

  .nav-link p {
    font-size: 14px;
  }

  .nav-header {
    border-bottom: 1px solid #494e5385;
    color: #fcf7ea;
  }

  .first-level {
    border-top: 1px solid #494e5385;
    border-radius: 5px;
    background-color: darken($left-menu-bgc, 5%) !important;
  }

  .second-level {
    border-bottom: 1px solid #494e5385;
    border-radius: 5px;
    //background-color: darken($left-menu-bgc, 8%) !important;
  }

  .third-level {
    border-top: 1px solid #494e5385;
    border-radius: 5px;
    //background-color: darken($left-menu-bgc, 10%) !important;
  }
}

.balance-big {
  .info-box-content {
    border-left: 2px solid rgba(0, 0, 0, 0.125)
  }
}

.main-screen {

  .timeline {
    & > div {
      margin-bottom: 0;
    }

    &.without-line::before {
      display: none;
    }
  }
}

.timeline-item {
  border: 1px solid #e1e1e1;

  &.bg-gray-border {
    border-left: 5px solid rgba(210, 214, 222, 0.6);
  }

  &.bg-red-border {
    border-left: 5px solid rgba(211, 55, 36, 0.8);
  }

  &.bg-purple-border {
    border-left: 5px solid rgba(96, 92, 168, 0.6);
  }

  &.bg-green-border {
    border-left: 5px solid rgba(0, 166, 90, 0.6);
  }

  &.bg-navy-border {
    border-left: 5px solid rgba(0, 31, 63, 0.8);
  }

  &.bg-yellow-border {
    border-left: 5px solid rgba(219, 139, 11, 0.6);
  }

  &.bg-maroon-border {
    border-left: 5px solid rgba(216, 27, 96, 0.8);
  }
}

.bg-aqua {
  background-color: #00c0ef !important;
}

.ft_logo img {
  height: 30px;
  width: auto;
}

.rating-star {
  color: #ffca00;
  font-size: 16px;
  margin-left: 5px;
  margin-bottom: 5px;
}

a.black-link {
  font-size: 16px;
  color: #000;
  text-decoration: underline;
}

.doer-rate {
  text-align: center;

  .rating {
    font-size: 38px;
    font-weight: bold;
    margin: 0;
    line-height: 1;
  }

  p {
    margin: 0;
  }
}

dl {
  margin-top: 0;
  margin-bottom: 20px
}

dd, dt {
  line-height: 1.42857143
}

dt {
  font-weight: 700
}

dd {
  margin-left: 0
}

@media (min-width: 768px) {
  .dl-horizontal {
    &.user-stat {
      dt {
        text-align: left;
      }

      dd {
        text-align: right;
      }
    }

    dt {
      float: left;
      width: 160px;
      clear: left;
      text-align: right;
      text-overflow: ellipsis;
      white-space: nowrap
    }

    dd {
      margin-left: 180px
    }
  }

}

.doer-task-text-wrapper {
  min-height: 375px;
  max-height: 500px;
  overflow-y: scroll !important;
}

.task-view {
  .card-footer {
    &.card-comments {
      padding: 0;

      .card-comment {
        padding: 10px;

        &:nth-child(even) {
          background: #f4f4f4;
        }
      }
    }
  }
}

.send-task-with-message-btn {
  display: none;
  margin-left: 10px;
}

.send-task-main-btn:hover .send-task-with-message-btn {
  display: inline-block;
}


.bg-default {
  background-color: #777;
}

.badge-default {
  background-color: #d2d6de;
  color: #444;
}

.switch-form {
  label {
    margin: 5px 10px;
    display: inline;
  }

  small {
    display: block;
  }

  .switcher {
    display: inline-block;
  }
}

.payment-icons {
  margin-bottom: 10px;
  padding: 0;

  .payment-visa {
    height: 50px;
  }

  .payment-master {
    height: 50px;
  }

  .payment-webmoney {
    height: 50px;
    margin: 0 10px;
  }

  .payment-io {
    height: 30px;
    width: auto;
    margin: 0 10px;
  }
}

.text-container {
  white-space: pre-wrap;
}

.links-text-view {
  height: 600px;
  background: #e2e2e2;
  padding: 10px;
  overflow-y: scroll;
}

.dialog {
  .selected-dialog-message {
    background: #d6ecea;
    box-sizing: border-box;
    display: block;
    clear: both;
    overflow: hidden;
  }

}

//$primary-color: #1F1A9C;
//$warning-color: #FAF402;
//.card-primary:not(.card-outline) > .card-header {
//  background-color: $primary-color
//}
//
//.card-primary.card-outline {
//  border-top: 3px solid $primary-color
//}
//
////.alert-warning {
////  background-color: #f39c12;
////  border-color: #e08e0b;
////  color: white;
////}

.btn-warn, .bg-warn {
  background-color: #f39c12;
  border-color: #e08e0b;
  color: white;

  &:hover {
    color: white;
  }
}

.doer-info {
  width: 300px;
}

.doer-popover {
  width: auto;
  opacity: 1 !important;
  pointer-events: inherit !important;
  z-index: 9999;
  border-color: #cccccc !important;
}

.doer-popover a:hover {
  color: #fff;
}

.doer-popover:before {
  border-bottom-color: #ccc;
  border-top-color: #ccc;
}

.__react_component_tooltip.type-light.border.place-right:before {
  border-right: 8px solid #c5c5c5;
}


.dimension {
  display: inline-block;
  margin-left: 5px;
  margin-bottom: 2px;
  font-size: 10px;
}

.label-item {
  background-color: #f4f4f4;
  color: #444;
  border-radius: 5px;
  border: 1px solid #ddd;
  line-height: 1.5;
  padding: 0 5px 1px;
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
}

.last-entering-date {
  color: #999;
  font-size: 11px;
  align-self: center;
}

span.wrapped {
  display: block;
  word-wrap: break-word;
  max-width: fit-content;
  white-space: normal
}


.lockEditor {
  padding: 5px;
  display: flex !important;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
  color: white;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}


.uniqueness-view a {
  cursor: pointer;
}

.uniqueness-view-sidebar {
  padding-left: 20px;
  margin-left: 0;
}

.uniqueness-view-text {
  background: #fff;
  max-height: 600px;
  overflow-y: scroll;
}

.uniqueness-view-switch-matches-btn {
  margin-left: 15px;
}

.uniqueness-view .uniqueness {
  font-size: 26px;
}

.link-black {
  color: #000 !important;
}

.link-black:hover {
  text-decoration: underline;
}

span[color='blue'] {
  background: #234280;
  color: #fff
}

span[color='red'] {
  background: #8f1309;
  color: #fff
}

span[color='yellow'] {
  background: #f8f42b;
}

span[color='orange'] {
  background: #ff7c1e;
}

span[color='green'] {
  background: #3cef55;
}

span[color='purple'] {
  background: #8e11d6;
  color: #fff
}

span[color='black'] {
  background: #000;
  color: #fff
}

.gainsboro {
  background: gainsboro;

  * {
    background: gainsboro;
  }
}

.revision {
  color: black;

  span.red {
    background: #d73925;
  }

  span.green {
    background: #008d4c;
  }
}

ins {
  p, li {
    color: black;
  }

  background: #008d4c;
  text-decoration: none;
}

del {
  background: #d73925;
  text-decoration: none;
}

ins, del {
  color: #fff
}

.modal-90w {
  width: 90%;
  max-width: none;
}


.links-preview .alignright {
  float: right;
  margin: 0 0 10px 10px;
}

.links-preview .alignleft {
  float: left;
  margin: 0 10px 10px 0;
}

.advice, .warning, .danger {
  border-width: 2px;
  border-style: solid;
  padding: 10px;
  margin: 10px 0;
}

.links-preview .advice {
  border-color: #1b7e5a;
}

.links-preview .warning {
  border-color: #dA570f;
}

.links-preview .danger {
  border-color: #be0025;
}

.zakon, .linkovka {
  padding: 10px;
  margin: 10px 0;
  background: #cccccc;
}

.links-preview h1, .links-preview h2, .links-preview h3, .links-preview h4, .links-preview h5, .links-preview h6 {
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  margin: 25px 0px 7px 0px;
}

.links-preview h1 {
  font-size: 30px;
  line-height: 37px;
}

.links-preview h2 {
  font-size: 28px;
  line-height: 33px;
}

.links-preview h3 {
  font-size: 24px;
  line-height: 29px;
}

.links-preview h4 {
  font-size: 20px;
  line-height: 27px;
}

.links-preview h5 {
  font-size: 22px;
  line-height: 24px;
}

.links-preview h6 {
  font-size: 21px;
  line-height: 24px;
}

.links-preview {
  white-space: pre-wrap;
}

.links-text-view {
  height: 600px;
  background: #e2e2e2;
  padding: 10px;
  overflow-y: scroll;
}

.links-preview table {
  border-collapse: collapse;
  border: 1px solid black;
}

.links-preview table td {
  border: 1px solid black;
}

.preview-links .modal-dialog {
  max-width: 750px;
  font-size: 18px;
}

.preview-links .links-preview * {
  /*//	margin-top: 18px;*/
}

#admin-review button[name="createButton"] {
  display: none;
}

.tox-tinymce {
  z-index: 0;
}

h3, h5 {
  &.card-title {
    color: inherit;
  }
}

.ant-notification {
  z-index: 999999999;
}


.not-found {
  color: #888888;
  font-style: italic;
}

.user-list-item {
  min-height: 30px;
  line-height: 30px;
}

.user-list li div, strong {
  text-align: center;
}


.ant-upload-list-item-name {
  text-align: left;
}

.uploader {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;

  .left {
    position: relative;
  }

  .btns-group {
    position: absolute;
    bottom: 0;
    right: 0;

    i.fa {
      color: #c0c0c0;
    }
  }

  p {
    text-align: center;
  }

  .btn.success {
    margin-left: auto;
    margin-top: 15px;
  }

  i.fa {
    font-size: 25px;
    color: black;
    cursor: pointer;

    &:hover {
      color: #f0f0f0;
    }
  }

}

.avatar {
  h4 {
    text-align: left;
  }

  .avatar-wrapper {
    display: inline-block;
  }

  .avatar-wrap {
    position: relative;
    overflow: hidden;
    height: 35px;
    width: 100px;
    margin: -35px 0 0px;

    &:hover .edit {
      opacity: 1;
      margin-top: 0
    }
  }

  .edit {
    color: #fff;
    opacity: 0;
    height: 35px;
    filter: alpha(opacity=0);
    background-color: #000;
    background-color: rgba(29, 32, 34, .7);
    width: 100px;
    margin-top: 40px;
    -o-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
    border-radius: 0 0 2px 2px;
    padding: 5px 0;
  }

  i {
    color: white;
    font-size: 25px;
  }
}

.fa-spin {
  color: white;
  font-size: 13px;
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

a .anchor {
  color: #3c8dbc;
}
